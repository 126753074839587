<template>
  <div>
    <CmpRenderer :components="components"></CmpRenderer>
    <Reassurance></Reassurance>
  </div>
</template>

<script>
import { HomeComponent } from 'src/modules/prismic/components/HomeComponent'
import config from 'config'
import Reassurance from '../components/Reassurance'

export default {
  name: 'HomePage',
  mixins: [HomeComponent],
  components: {
    Reassurance
  },
  asyncData({ context }) {
    return new Promise((resolve, reject) => {
      if (context) context.output.cacheTags.add(`home`)
      return resolve()
    })
  },
  computed: {
    clientUrl() {
      return config.client.url
    }
  }
}
</script>
