import { mapGetters, mapActions } from 'vuex'
import { metaInfoBuilder, kebabToSnake } from '../utilities'
import { PAGES, BLOCKS, UIDS } from '../content-types';
const type = PAGES.HOMEPAGE
const uid = UIDS.HOMEPAGE

export const HomeComponent = {
  name: 'HomeComponent',
  computed: {
    ...mapGetters('prismic', ['getPage', 'getBlock', 'getAlternateLanguages']),
    components() {
      return (this.page && this.page.content || [])
        .map(component => {
          const block = this.centralizedBlocks.find(b => component.component === kebabToSnake(b))
          return this.getBlock(block) || component
        })
    }
  },
  methods: {
    ...mapActions('prismic', ['setPage', 'setBlock'])
  },
  async serverPrefetch() {
    const blocks = this.centralizedBlocks
      .map(_uid => this.setBlock(_uid))
    const page = this.setPage({ type, uid })
    await Promise.all([...blocks, page])
      .then(() =>  this.page = this.getPage({ type, uid }))
  },
  created() {
    const blocks = this.centralizedBlocks
      .map(_uid => this.setBlock(_uid))
    const page = this.setPage({ type, uid })
    Promise
      .all([...blocks, page])
      .then(() => this.page = this.getPage({ type, uid }))
  },
  metaInfo() {
    const page = this.page
    return page ? metaInfoBuilder(page, this.$route, this.getAlternateLanguages({type, uid})) : {}
  },
  data() {
    return {
      page: null,
      centralizedBlocks: [
        BLOCKS.TAGLINE_HORIZONTAL_AUTO_SCROLL,
        BLOCKS.TAGLINE_HORIZONTAL_AUTO_SCROLL_PANTONE,
        BLOCKS.COLLECTIONS_THUMBS,
        BLOCKS.BASELINE_GIF,
        BLOCKS.STORE_LOCATOR_WIDGET,
      ]
    }
  }
}
